/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

/** @namespace Scandiweb/Gtm/Event/Cart/fireAddToCartEvent */
export const fireAddToCartEvent = async (item, currencyCode, customerId, realQuantity, gtmCategory) => {
    // ^^^ not using debounce, as many cart edits may happen at once

    if (window.gtmCartItemsQty?.[item.id] === realQuantity) {
        return;
    }

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Cart/fireAddToCartEvent/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { getAddToCartData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "cart-util" */
                '../data/cart'
            );
            const { GTM_EVENT_KEY_ADD_TO_CART } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_ADD_TO_CART,
                customerId,
                ...await getAddToCartData(item, currencyCode, gtmCategory)
            });

            window.gtmCartItemsQty = { [item.id]: realQuantity };
        }
    );
};

/** @namespace Scandiweb/Gtm/Event/Cart/fireRemoveFromCartEvent */
export const fireRemoveFromCartEvent = async (item, currencyCode, customerId, realQuantity, prevQty, gtmCategory) => {
    // ^^^ not using debounce, as many cart edits may happen at once

    if (window.gtmCartItemsQty?.[item.id] === realQuantity && prevQty === realQuantity) {
        return;
    }

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Cart/fireRemoveFromCartEvent/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { getRemoveFromCartData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "cart-util" */
                '../data/cart'
            );
            const { GTM_EVENT_KEY_REMOVE_FROM_CART } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_REMOVE_FROM_CART,
                customerId,
                ...await getRemoveFromCartData(item, currencyCode, gtmCategory)
            });

            window.gtmCartItemsQty = { [item.id]: realQuantity };
        }
    );
};

/** @namespace Scandiweb/Gtm/Event/Cart/fireViewCartEvent */
export const fireViewCartEvent = async (items, cartSubtotal, currencyCode, customerId, gtmCategory) => {
    // ^^^ not using debounce, as many cart edits may happen at once
    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Cart/fireViewCartEvent/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { getCartItemsData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "cart-util" */
                '../data/cart'
            );
            const { CHECKOUT_STEP_MAP, DL_VAL_CHECKOUT_CART_STEP } = await import(
                /* webpackMode: "lazy", webpackChunkName: "checkout-util" */
                '../data/checkout'
            );
            const { GTM_EVENT_KEY_CHECKOUT } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_CHECKOUT, // view cart changed to checkout step 1
                customerId,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: DL_VAL_CHECKOUT_CART_STEP,
                            action: CHECKOUT_STEP_MAP[DL_VAL_CHECKOUT_CART_STEP]
                        },
                        products: await getCartItemsData(gtmCategory)
                    }
                }
            });
        }
    );
};
